import CableReady from 'cable_ready'
import consumer from "./consumer"

consumer.subscriptions.create("LiveOrderChannel", {
  
  // connected() {
  //   // Called when the subscription is ready for use on the server
  // },
  //
  // disconnected() {
  //   // Called when the subscription has been terminated by the server
  // },

received(data) {
  if (data.cableReady) {
    
    // if (Array.isArray(data.operations)) {
      
    //   const accountOperations = data.operations.filter(op => 
    //     op.html?.startsWith('<tbody id="account_') && op.selector?.startsWith("#account_")
    //   );
    
    //   if (accountOperations.length > 0) {

    //     const liveStats = accountOperations.find(op => 
    //       document.querySelector(op.selector)
    //     );
    
    //     if (liveStats) {
    //       window.location.reload();
    //       return;
    //     }
        
    //   }
    // }

      CableReady.perform(data.operations);
  }
}
  
});
